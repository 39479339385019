<script setup lang="ts">
import { Calendar } from '@/interfaces';
import { useCalandarDictionaries, getDateNullTime, getMonthList } from '@/utils';

const emit = defineEmits(['selectMonth']);
const props = defineProps<{
  month: Date;
  dates: Calendar['dates'];
  type: Calendar['options']['type'];
}>();

const { MonthNamesCir } = useCalandarDictionaries()

const isSeveralType = props.type === 'SEVERAL';

const months = computed(() => {
  return getMonthList(props.month);
});

const isSelectedMonth = (date: Date) => {
  return (
    Number(date) === Number(getDateNullTime(props.dates.startedAt as Date, 'month')) ||
    Number(date) === Number(getDateNullTime(props.dates.endedAt as Date, 'month'))
  );
};

const isBetweenMonth = (date: Date) => {
  if (!props.dates.startedAt || !props.dates.endedAt) return false;
  if (
    props.dates.startedAt.getFullYear() < date.getFullYear() &&
    props.dates.endedAt.getFullYear() > date.getFullYear()
  )
    return true;
  return false;
};
</script>

<template>
  <div class="common-calendar--wrapper">
    <div class="months flex flex-ww">
      <div
        class="month cursor-pointer"
        v-for="(date, index) of months"
        :key="index"
        :class="[
          { selected: isSelectedMonth(date) },
          { between: isSeveralType && isBetweenMonth(date) },
        ]"
        @click.stop="emit('selectMonth', date)"
      >
        {{ MonthNamesCir[date.getMonth()] }}
      </div>
    </div>
  </div>
</template>
