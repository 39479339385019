<script setup lang="ts">
import { Calendar } from '@/interfaces';
import dateFilter from '@/filters/date.filter';
import { useCalandarDictionaries, getDateNullTime, getWeeks } from '@/utils';

const emit = defineEmits(['selectDate']);

const props = defineProps<{
  month: Date;
  dates: Calendar['dates'];
  show: Calendar['options']['show'];
}>();

const { getDayWeekList } = useCalandarDictionaries()

const state = reactive({
  dayweek: getDayWeekList(),
});

const isSelectedDate = (date: Date) => {
  return (
    dateFilter(date) === dateFilter(props.dates.startedAt as Date) ||
    dateFilter(date) === dateFilter(props.dates.endedAt as Date)
  );
};

const isCurrentDate = (date: Date) => {
  return dateFilter(date) === dateFilter(new Date());
};

const isBetweenDate = (date: Date) => {
  if (!props.dates.startedAt || !props.dates.endedAt) return false;
  if (
    Number(getDateNullTime(props.dates.startedAt as Date)) < Number(date) &&
    Number(getDateNullTime(props.dates.endedAt as Date)) > Number(date)
  )
    return true;
  return false;
};

const selectDate = (date: Date) => {
  const current = new Date();
  date = new Date(date.setHours(current.getHours(), current.getMinutes()));

  if (!(props.show.endedAt && props.show.startedAt)) {
    const current_type = props.show.startedAt ? 'startedAt' : 'endedAt';
    props.dates[current_type] = date;
    emit('selectDate');
    return;
  }
  selectSeveralDate(date);
};

const selectSeveralDate = (date: Date) => {
  if (props.dates.startedAt && props.dates.endedAt) {
    props.dates.startedAt = date;
    props.dates.endedAt = null;
  } else if (props.dates.startedAt) {
    if (Number(date) < Number(props.dates.startedAt)) {
      props.dates.endedAt = props.dates.startedAt;
      props.dates.startedAt = date;
    } else {
      props.dates.endedAt = date;
    }
  } else if (!props.dates.startedAt) {
    if (Number(date) > Number(props.dates.endedAt)) {
      props.dates.startedAt = props.dates.endedAt as Date;
      props.dates.endedAt = date;
    } else {
      props.dates.startedAt = date;
    }
  }
  emit('selectDate');
};

const weekList = computed(() => {
  return getWeeks(props.month) as Record<string, any>[][];
});
</script>

<template>
  <div class="common-calendar--wrapper">
    <div class="dayweek flex">
      <div class="day" v-for="day of state.dayweek" :key="day">{{ day }}</div>
    </div>
    <div class="weeks">
      <div class="week flex" v-for="(week, index) of weekList" :key="index">
        <div
          class="date cursor-pointer"
          @click="selectDate(date.value)"
          :class="[
            date.type,
            { today: isCurrentDate(date.value) },
            { selected: isSelectedDate(date.value) },
            { between: show.endedAt && show.startedAt && isBetweenDate(date.value) },
          ]"
          v-for="(date, index) of week"
          :key="index"
        >
          {{ date.value.getDate() }}
        </div>
      </div>
    </div>
  </div>
</template>
