<script setup lang="ts">
import { Calendar } from '@/interfaces';
import dateFilter from '@/filters/date.filter';

const { t } = useI18n();

const { dates } = defineProps<{
  dates: Calendar['dates'];
}>();

const currentPeriod = computed(() => {
  return !dates.startedAt
    ? t('components.catalog.filter.allTime')
    : `${dateFilter(dates.startedAt as Date, 'dateyear')} - ${dateFilter(
        dates.endedAt as Date,
        'dateyear',
      )}`;
});
</script>

<template>
  <div class="calendar-header flex flex-ic">
    <common-icon-common-calendar size="16px"></common-icon-common-calendar>
    <span>{{ currentPeriod }}</span>
  </div>
</template>

<style scoped lang="scss">
.calendar-header {
  @apply text-sm py-2 px-3 rounded-md gap-2 border border-solid border-gray-4 cursor-pointer w-max;
}
</style>
