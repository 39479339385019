<script setup lang="ts">
  import { switchMonth } from '@/utils/calendar';
  import { CSSProperties } from 'nuxt/dist/app/compat/capi';
  import { Calendar, CalendarMode, CalendarOptions } from '@/interfaces';

  const { t } = useI18n();

  const { MonthNames } = useCalandarDictionaries()

  const emit = defineEmits(['selectDate', 'destroyCalendar']);
  const props = defineProps<{
    options: CalendarOptions;
    dates: Calendar['dates'];
  }>();

  const state = reactive({
    month: new Date(),
    mode: 'dates' as CalendarMode,
  });

  const modeHandler = () => {
    if (state.mode === 'dates') return (state.mode = 'months');
    if (state.mode === 'months') return (state.mode = 'years');
    if (state.mode === 'years') return (state.mode = 'dates');
  };

  const updateMonth = (inc: number) => {
    state.month = switchMonth(state.month, inc, state.mode);
  };

  const selectMonth = (date: Date) => {
    state.month.setMonth(date.getMonth(), 1);
    state.mode = 'dates';
  };

  const selectYear = (date: Date) => {
    state.month.setFullYear(date.getFullYear());
    state.mode = 'months';
  };

  const monthFilter = computed(() => {
    const month = MonthNames[state.month.getMonth()];
    const year = state.month.getFullYear();

    if (state.mode === 'dates') return `${month}, ${year}`;
    if (state.mode === 'months') return `${year}`;

    const firstYear = Math.floor(year / 10) * 10;
    return `${firstYear} - ${firstYear + 10}`;
  });

  const selectDates = () => {
    if (props.options.applied) return;
    emit('selectDate');
  };

  const selectDate = () => {
    if (!props.dates.endedAt || !props.dates.startedAt) return;

    props.dates.startedAt = new Date(new Date(props.dates.startedAt).setHours(0, 0, 0));
    props.dates.endedAt = new Date(new Date(props.dates.endedAt).setHours(23, 59, 59));
    emit('selectDate');
  }

  const getCurrentStyle = () => {
    const styles = {} as CSSProperties;
    styles[props.options.right ? 'right' : 'left'] = '0px';
    return styles;
  };
</script>

<template>
  <div class="common-calendar" :style="getCurrentStyle()">
    <div class="common-calendar--container">
      <div class="common-calendar--month flex-c">
        <div class="prev flex-c" @click="updateMonth(-1)">
          <common-icon-arrow-down class-list="white_filter"></common-icon-arrow-down>
        </div>
        <div class="type-name cursor-pointer" @click="modeHandler">
          {{ monthFilter }}
        </div>
        <div class="next flex-c" @click="updateMonth(1)">
          <common-icon-arrow-down class-list="white_filter"></common-icon-arrow-down>
        </div>
      </div>
      <div class="common-calendar--mode">
        <common-calendar-container-mode-dates
          v-if="state.mode === 'dates'"
          :dates="props.dates"
          :month="state.month"
          :show="props.options.show"
          @select-date="selectDates"
        ></common-calendar-container-mode-dates>
        <common-calendar-container-mode-months
          v-if="state.mode === 'months'"
          :type="props.options.type"
          :dates="props.dates"
          :month="state.month"
          @select-month="selectMonth"
        ></common-calendar-container-mode-months>
        <common-calendar-container-mode-years
          v-if="state.mode === 'years'"
          :type="props.options.type"
          :dates="props.dates"
          :month="state.month"
          @select-year="selectYear"
        ></common-calendar-container-mode-years>
      </div>
      <template v-if="state.mode === 'dates' && options.applied">
        <div class="common-calendar--action flex flex-je">
          <button class="btn btn-white btn-small" @click="selectDate">{{ t('components.catalog.filter.apply') }}</button>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import './calendar.scss';
.common-calendar {
  width: 302px;
}
</style>
