<script setup lang="ts">
import { computed, reactive } from 'vue';
import { Calendar, CalendarOptions } from '@/interfaces';

const emit = defineEmits<{
  (e: 'apply', dates: Calendar['dates']): void;
}>();

const props = defineProps<{
  dates: Calendar['dates'];
}>();

const options: CalendarOptions = {
  id: 'calendar',
  type: 'SEVERAL',
  show: {
    startedAt: true,
    endedAt: true,
  },
  applied: true,
};

const state = reactive({
  dates: props.dates,
});
</script>

<template>
  <div class="calendar">
    <common-drop :container="{ top: '46px', right: '0px', maxHeight: 'unset' }">
      <template #header>
        <common-calendar-header :dates="dates"></common-calendar-header>
      </template>
      <template #container="{ toggle }">
        <common-calendar-container
          :dates="state.dates"
          :options="options"
          @select-date="
            () => {
              toggle(false);
              emit('apply', state.dates);
            }
          "
        />
      </template>
    </common-drop>
  </div>
</template>

<style scoped lang="scss"></style>
