<script setup lang="ts">
import { Calendar } from '@/interfaces';
import { getDateNullTime, getYearList } from '@/utils';

const emit = defineEmits(['selectYear']);
const props = defineProps<{
  month: Date;
  dates: Calendar['dates'];
  type: Calendar['options']['type'];
}>();

const isSeveralType = props.type === 'SEVERAL';

const years = computed(() => {
  return getYearList(props.month);
});

const isSelectedYear = (date: Date) => {
  return (
    Number(date) === Number(getDateNullTime(props.dates.startedAt as Date, 'year')) ||
    Number(date) === Number(getDateNullTime(props.dates.endedAt as Date, 'year'))
  );
};

const isBetweenYear = (date: Date) => {
  if (!props.dates.startedAt || !props.dates.endedAt) return false;
  if (
    props.dates.startedAt.getFullYear() < date.getFullYear() &&
    props.dates.endedAt.getFullYear() > date.getFullYear()
  )
    return true;
  return false;
};
</script>

<template>
  <div class="common-calendar--wrapper">
    <div class="years flex flex-ww">
      <div
        class="year cursor-pointer"
        v-for="(date, index) of years"
        :key="index"
        :class="[
          { selected: isSelectedYear(date) },
          { between: isSeveralType && isBetweenYear(date) },
        ]"
        @click.stop="emit('selectYear', date)"
      >
        {{ date.getFullYear() }}
      </div>
    </div>
  </div>
</template>
